.user-info-title {
    color: #ED2973;
    font-weight: 500;
}

.avatar-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
}

.avatar {
    background: #fff;
    text-align: center;
    align-items: center;
    padding-top: 60px;

    .avatar-img {
        width: 100%;
        height: 100%;
        max-width: 120px;
        max-height: 120px;
        margin: auto;
        background-repeat: no-repeat;
        position: relative;

        .camera-icon {
            background-image: url("../../assets/image/camera-icon.png");
            background-repeat: no-repeat;
            width: 30px;
            height: 30px;
            position: absolute;
            bottom: 0;
            right: 0;
            cursor: pointer;
        }
    }
}

.tab-info {
    background: #fff;
    text-align: center;
    padding: 20px;
}

.input-info {
    border-radius: 5px !important;
}

.react-tel-input .form-control {
    border-radius: 5px !important;
}

.btn-submit {
    border-radius: 15px !important;
    background: linear-gradient(44.02deg, #ED2973 5.97%, #FE7249 94%);
}