.reflect-detail {
  h1 {
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #ed2973;
  }
  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #ed2973;
  }
  .MuiSvgIcon-root{
    margin-bottom: 2px;
    width: 0.8em!important;
    height: 0.8em!important;
  }
  span{
    margin-right: 15px;
  }
  .text-blue {
    color: #2a72de;
  }
  .text-grey {
    color: #959595;
  }

  .relfect-media-list {
    > div {
      width: 60%;
      @media (min-width: 687px) {
      }
    }
  }

  .reflect-vote {
    padding: 30px;
    background: #f3f3f3;
    border-radius: 6px;
    h3 {
      color: #2a72de;
      font-size: 20px;
      font-weight: 600;
      line-height: 1;
      text-align: center;
    }
    &__action {
      margin-top: 36px;
      display: flex;
      justify-content: space-around;
      .reflect-vote__icon {transition: all 150ms ease-in-out 0ms;
        cursor: pointer;
        &:hover {
          transform: scale(1.05);
        }
        > div {
          display: block;
          width: 56px;
          height: 56px;
          margin: auto;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
        }
        .love {
          background-image: url(/assets/reflect/reaction/love-gray.png);
        }
        &:hover .love,
        .love.active {
          background-image: url(/assets/reflect/reaction/love-color.png);
        }
        .smile {
          background-image: url(/assets/reflect/reaction/smile-gray.png);
        }
        &:hover .smile,
        .smile.active {
          background-image: url(/assets/reflect/reaction/smile-color.png);
        }
        .sad {
          background-image: url(/assets/reflect/reaction/sad-gray.png);
        }
        &:hover .sad,
        .sad.active {
          background-image: url(/assets/reflect/reaction/sad-color.png);
        }
      }
      p {
        color: #6a6a6a;
        font-weight: 5000;
        margin-top: 8px;
      }
    }
  }
}

.reflect-pagination {
  display: flex;
  justify-content: center;
  ul > li button.Mui-selected {
    background: linear-gradient(44.02deg, #ed2973 5.97%, #fe7249 94%);
    color: #fff;
    border: none;
  }
}


