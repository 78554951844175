.reflect {
  .reflect__desktop {
    display: none;
  }
  .reflect__mobile {
    width: 100%;
    display: block;
  }

  @media (min-width: 992px) {
    .reflect__desktop {
      display: flex;
    }
    .reflect__mobile {
      display: none;
    }
  }

  .reflect-category {
  }

  .category-slider {
    box-shadow: 0px 0px 20px rgba(0, 69, 99, 0.07);
    background-color: #fff;
    border-radius: 32px;
    padding: 8px 6px;
    width: 100%;
    .slick-slider {
      width: 100%;
      .slick-arrow {
        &.slick-prev {
          left: -15px;
          &::before {
            background-image: url(/assets/reflect/icon-prev.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center right;
          }
        }
        &.slick-next {
          right: -15px;
          &::before {
            background-image: url(/assets/reflect/icon-next.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center left;
          }
        }
      }
    }
    .category-slider__item {
      padding: 0 3px;
      @media (min-width: 420px) {
        padding: 0 16px;
      }
      .category-slider__image {
        position: relative;
        padding-top: 100%;
        border-radius: 16px;
        transform: scale(0.7);
        img {
          position: absolute;
          inset: 0;
          width: 100%;
          object-fit: contain;
          transform: scale(0.7);
        }
      }
      .category-slider__name {
        font-size: 12px;
        font-weight: 500;
        color: #262a37;
        text-align: center;
      }
      &:hover .category-slider__name {
        color: #ed2973;
      }
    }
    .slick-slide {
      &:nth-child(odd) .category-slider__image {
        background: linear-gradient(44.02deg, #ed2973 5.97%, #fe7249 94%);
      }
      &:nth-child(even) .category-slider__image {
        background: linear-gradient(44.02deg, #956aea 5.97%, #56d6e5 94%);
      }
    }
  }

  .shadow {
    box-shadow: 0px 0px 10px rgba(0, 69, 99, 0.04) !important;
  }
}

.add-reflect {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: fixed;
  bottom: 0px;
  right: 20px;

  .icon {
    z-index: 1;
    animation: scaleAnimation 3.5s infinite;
  }

  .text {
    background: linear-gradient(44.02deg, #ed2973 5.97%, #fe7249 94%);
    box-shadow: 0px 0px 15px rgba(237, 41, 115, 0.25);
    border-radius: 30px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #ffffff;
    margin-left: -70px;
    padding: 15px 20px 15px 50px;
  }
}

@-webkit-keyframes scaleAnimation {
  0%,
  80%,
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  30%,
  50% {
    -webkit-transform: scale(1.3, 1.3);
    transform: scale(1.3, 1.3);
  }
}
