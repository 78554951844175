.reflect-header.standard {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;

  .reflect-header__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    background: linear-gradient(to bottom, #956aea, #56d6e5);
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    color: #fff;
    padding: 0 12px;
    border-radius: 6px 6px 0 0;
    @media (min-width: 576px) {
      height: 50px;
      font-size: 20px;
      padding: 0 6px;
    }
  }
  .reflect-header__read-more {
    white-space: nowrap;
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.25;
    color: #2a72de;
    cursor: pointer;
    @media (min-width: 576px) {
      font-size: 16px;
    }
  }
  & + .reflect-header__divider {
    border-bottom: #ececec solid 1px;
  }
}

.reflect-header.section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .reflect-header__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #2a72de;
  }
  .reflect-header__read-more {
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ed2973;
    cursor: pointer;
  }
}

.reflect-page-header {
  // padding-top: 80px;
  background-image: url(/assets/reflect/bg-header.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  &__content {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      color: #ffffff;
      @media (min-width: 767px) {
        font-size: 40px;
        line-height: 48px;
      }
    }
  }
  &__breadcumb {
    > a:not(:last-child)::after {
      content: '·';
      padding: 0 10px;
    }
    a {
      font-size: 12px;
      line-height: 13px;
      color: #ffffff;
      @media (min-width: 767px) {
        font-size: 16px;
        line-height: 19px;
      }
      &:hover {
        color: #ffffff;
      }
    }
  }
}
.map-paht {
  float: right;
  padding-left: 2.5rem!important;
  button:hover {
    color: #ffff00;;
  }
}
