#layout-profile-container {
  min-height: calc(100vh - 61px);
  .sidebar {
    > a {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-weight: 500;
      padding: 16px 20px;
      color: #6a6a6a;
      p {
        margin: 0;
        font-weight: 300;
        padding-left: 4px;
      }
      &.active {
        background: linear-gradient(44.02deg, #956aea 5.97%, #56d6e5 94%);
        color: #fff;
      }
    }
  }

  .left-nav {
    background: #fff;
  }

  .nav-item-profile {
    padding: 12px;
    width: 100%;
    cursor: pointer;
    text-align: left;
    padding-left: 20px;
    font-size: 14px;
    color: #fff !important;
  }

  .bg-item-selected {
    color: #fff;
    background-color: #cc0902;
    border-bottom: 1px solid #fff;
  }

  .bg-item {
    color: #fff;
    background-color: #666;
    border-bottom: 1px solid #fff;
  }
}

.nav {
  list-style: none;
  display: flex !important;
  border-radius: 2px;
}
