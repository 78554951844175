.react-select-container {
  &.first-load {
    .react-select__menu {
      display: none !important;
    }
  }

  .react-select__control {
    border-radius: 24px;

    .react-select__value-container {
      padding-left: 15px;
      cursor: text;

      .react-select__placeholder {
        font-size: 14px;
      }

      .react-select__single-value,
      .react-select__input {
        font-size: 14px;
      }
    }

    .react-select__indicators {
      .react-select__indicator-separator {
        width: 0;
        margin: 0;
      }

      .react-select__indicator {
        &.react-select__dropdown-indicator {
          display: none;
        }

        &.react-select__clear-indicator {
          margin-right: 10px;
        }

        color: #ffffff;
        padding: 5px;

        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}

.home-page {
  .home-search {
    padding: 0.6rem 0;
    position: relative;
    z-index: 1000;

    .input-search {
      width: 100%;
      border: 1px solid hsl(0, 0%, 80%);
      color: #000;
      border-radius: 24px;
      min-height: 38px;
      padding: 0px 15px;

      &::placeholder {
        font-weight: normal;
        font-size: 14px;
        color: #a4a4a4;
      }
    }

    .input-autosuggest {
      width: 93%;
      border: 1px solid hsl(0, 0%, 80%);
      color: #000;
      border-radius: 24px;
      min-height: 38px;
      padding: 0px 15px;

      &::placeholder {
        font-weight: normal;
        font-size: 14px;
        color: #a4a4a4;
      }
    }

    .search-input-planning {
      position: relative;

      .list-group {
        margin-left: 36px;

        .list-group-item {
          margin-top: 8px;
          padding: 0.3rem 1rem;
          position: absolute;
          top: 31px;
          z-index: 1;
          width: 88%;
        }
      }
    }
  }

  .slider-home-page {
    position: relative;
    top: -57px;
    .slick-slider {
      .slick-dots {
        bottom: 30px;

        li {
          width: 10px;
          height: 10px;
          margin: 3px;

          button {
            width: 12px;
            height: 12px;
            background-color: rgba(0, 0, 0, 0);
            border: 1px solid #fff;
            border-radius: 50%;
            padding: 4px;
          }

          button::before {
            font-size: 12px;
            width: 12px;
            height: 12px;
            opacity: 0;
            color: #fff;
            line-height: 15px;
          }

          &.slick-active {
            button::before {
              opacity: 1;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .home-map {
    position: relative;

    &:focus {
      outline: none;
    }

    img {
      width: 100%;
      height: 22.5rem;
      object-fit: cover;
    }

    .map-content {
      position: absolute;
      top: 0;
      right: 300px;
      width: 23rem;
      height: 100%;
      background: linear-gradient(0deg, #1883db, #1883db);

      .title {
        font-weight: bold;
        font-size: 1.125rem;
        text-align: center;
        color: #ffffff;
      }

      hr {
        border-color: #ffffff;
      }

      .content {
        font-weight: normal;
        font-size: 1rem;
        text-align: justify;
        color: #ffffff;
      }
    }
  }

  .home-content {
    position: relative;
    top: -1.875em;

    // .title {
    //   font-weight: bold;
    //   font-size: 22px;
    //   text-align: center;
    //   color: #333333;
    //   margin-bottom: 4px;
    //   margin-top: 25px;
    // }

    // hr {
    //   border-width: 2px;
    //   border-color: #ffce38;
    //   width: 35%;
    //   margin-top: 0;
    // }

    .content {
      .item {
        margin-bottom: 1rem;
        margin-top: 10px;
        padding-right: 0.8em;
        padding-left: 0.8em;

        a:hover{
          text-decoration: none;
        }
        .item-block{
          border: 1px solid #1883db;
          border-radius: 8px;
          box-shadow: 0 0 8px #1883db;

          &:hover{
            box-shadow: 0px 4px 20px #1883db;
          }

          span {
            font-weight: bold;
            text-align: center;
            color: #1883db;
            // white-space: nowrap;
            font-size: 20px;
            margin: 20px auto;
            display: flex;
            justify-content: center;
          }
  
          img{
            display: flex;
            align-items: center;
            margin: 20px auto;    
          }
        }
        
      }
    }
  }

  .react-autosuggest__input {
    width: 90%;
    border-style: none;
    position: absolute;
  }

  .map__search-bar-container {
    .map__map-maker-icon {
      color: #fd6c6c;
    }

    .map__github-icon {
      font-size: 24px;
    }

    .map__github-link {
      color: #262626;
      font-size: 20px;
    }

    .map__github-link:hover,
    .map__github-link:active {
      color: #4078c0;
      text-decoration: none;
    }

    .map__suggestion-icon {
      margin-right: 8px;
    }

    .map__search-input-container {
      position: relative;
    }

    .map__clear-button,
    .map__clear-button:active,
    .map__clear-button:focus {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      background: transparent;
      border: none;
      outline: none;
      font-weight: 600;
      color: #999;
    }

    .map__autocomplete-container {
      border-bottom: honeydew;
      border-left: honeydew;
      border-right: honeydew;
      border-top: 1px solid #e6e6e6;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      border-radius: 0 0 2px 2px;
      position: absolute;
      z-index: 1;
      width: 95%;
    }

    .map__suggestion-item {
      padding: 8px;
      text-align: left;
      background-color: #fff;
      cursor: pointer;
      border-bottom: 1px solid #e6e6e6;
    }

    .map__suggestion-item--location-pointer {
      width: 16px;
      height: 16px;
    }

    .map__suggestion-item--active {
      background-color: #fafafa;
    }

    .map__dropdown-footer {
      display: flex;
      justify-content: flex-end;
      padding: 4px;
    }

    .map__dropdown-footer-image {
      display: inline-block;
      width: 150px;
    }

    .map__spinner {
      color: #18bc9c;
      font-size: 30px;
    }

    .map__error-message {
      color: red;
    }

    .map__geocode-result-header {
      font-size: 20px;
      color: #222222;
    }
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1400px) {
  .home-page {
    .home-map {
      .map-content {
        right: 215px;
      }
    }
  }
}

@media (max-width: 1030px) {
    .home-page .home-content {
        .content {
            .item {
                span {
                    font-size: inherit;
                }
            }
        }
    }
}