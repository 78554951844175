.openlayer-map-view-container {

  #tooltip-container-openlayer {
    position: fixed;
    opacity: 0;
    padding: 15px;
    background-color: white;
    border-radius: 5px;
  }
  .ol-dragbox {
    background-color: rgba(192, 68, 68, 0.4);
    border-color: rgba(100, 150, 0, 1);
  }
  #popup-container-openlayer {
    position: fixed;
    opacity: 0;
    padding: 15px;
    background-color: white;
    border-radius: 5px;
  }
  #mouse-position {
    left: 420px;
    position: fixed;
    bottom: 5px;
    color: yellow;
  }
  #title {
    font-weight: bold;
  }
}
.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}
#map-scale {
  right: 50px;
  position: fixed;
  bottom: 5px;
}