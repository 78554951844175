.reflect-card {
  box-shadow: 0px 0px 20px rgba(0, 69, 99, 0.07);
  display: flex;
  border-radius: 8px;
  transition: all 150ms ease-in-out 0ms;
  cursor: pointer;
  overflow: hidden;
  height: 100%;
  background-color: #fff;
  &:hover {
    transform: scale(1.02);
  }
  img {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 6px;
    @media (min-width: 668px) {
      height: 110px;
      width: 110px;
    }
  }
  .card-content {
    flex-grow: 1;
    &__type {
      display: inline-block;
      padding: 4px 6px;
      background: linear-gradient(to right, #ed2973, #fe7249);
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      border-radius: 4px;
    }
    &--red {
      background: linear-gradient(44.02deg, #ed2973 5.97%, #fe7249 94%);
      border-radius: 6px;
    }

    &--blue {
      background: linear-gradient(44.02deg, #956aea 5.97%, #56d6e5 94%);
      font-size: 12px;
      border-radius: 6px;
    }

    &__placement {
      justify-content: flex-start;
      font-size: 12px;
      line-height: 15px;
      color: #2a72de;
      margin-top: 4px;

      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      .MuiSvgIcon-root {
        font-size: 12px;
        margin-bottom: 1px;
      }
    }
    &__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      color: #212121;
      margin-top: 4px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &__time {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 12px;
      line-height: 15px;
      color: #ed2973;
      margin-top: 4px;
      .MuiSvgIcon-root {
        font-size: 12px;
        margin-bottom: 2px;
        margin-right: 2px;
      }
    }
    &__description {
      font-size: 14px;
      line-height: 1.2;
      color: #585858;
      margin-top: 4px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  &.reflect-card__vertical {
    position: relative;
    flex-direction: column;
    img {
      width: 100%;
      min-height: 200px;
      object-fit: cover;
    }
    .card-content {
      padding: 20px;
      &__type {
        position: absolute;
        top: 12px;
        left: 12px;
      }
      &__title {
        font-size: 18px;
      }
    }
  }

  &.reflect-card__horizontal {
    min-height: 135px;
    padding: 20px;
    .card-content {
      width: calc(100% - 120px);
      padding-left: 10px;
    }
  }
}
