.left-menu-custom-container {
    width: 320px;
    max-width: 320px;
    background-color: white;
    overflow-y: auto;
    padding-bottom: 50px;

    .search-groups {
        .container {
            margin-top: 10px;

            .search-by-my-position {
                padding: 6px 14px;
                border-radius: 4px;
            }
        }
    }

    .disabled {
        pointer-events: none;
        cursor: none;
    }

    .title-container {
        font-size: 16px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        background-color: #CC0902;
        text-decoration: none;
        cursor: pointer;
        margin-bottom: 1px;
    }

    .render-group-planning {
        .title-container {
            background-color: #666666;
            &:hover{
                background-color: #CC0902;
            }
        }

        label {
            display: block;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}