.planning-type {
  background: #EEEEEE;
  padding: 30px 0 0;
}
.planning-type1 {
  background: #ffffff;
  padding: 30px 0 0;
}
.nav-action {
  cursor: pointer;
  display: inline-block;
  width: 22px;
  line-height: 22px;
  height: 22px;
  border: 1px solid #333333;
  text-align: center;
  margin-left: 5px;

  svg {
    vertical-align: middle;
    height: 20px;
  }
}


.planning-type-items {
  border: 1px solid #007bff;
  border-radius: 5px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 5em;
  padding-top: 20px;
}

.planning_type {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.title__planning-type h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  color: #3342B9;
  border-left: 3px solid #FFAF18;
  padding-left: 5px;
  margin-bottom: 20px;
}

.planning-img {
  width: 98%;
  margin-bottom: 10px;
  border-radius: 5px;
}

.first-img {
  width: 98%;
  margin-bottom: 10px;
  border-radius: 5px;
}

.planning__item {
  margin-bottom: 20px ;
}

.planning__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #333333;
  display: block;
  margin: 10px 0 5px;
}

.planning__date {
  font-size: 14px;
  line-height: 19px;
  color: #A4A4A4;
  margin-bottom: 20px;
}
.pagination-nav {
  text-align: right;
}